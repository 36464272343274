<template>
  <div
    class="risk-management-programs px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Risk Management Programs" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/Arch-Smooth-min.png"
              alt=""
              title="rmp"
              class="img-responsive wp-image-12037"
          /></span>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <Paragraph>
            <p>
              This arch pictorially represents how the requirements of RMP work
              to protect the public and environment. See the discussion of each
              arch segment below to review the regulatory requirements, how
              Safety Management Services, Inc. (SMS) can support the
              implementation of these requirements, and review what is required
              of your company.
            </p>
            <p>
              SMS offers training courses on Process Safety Management and Risk
              Management Programs.
            </p>
          </Paragraph>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full mb-6">
        <TitleComponent title="Elements of Risk Management Programs" />
        <TabsComponent :tabs="tabs" />
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";
import TabsComponent from "@/components/TabsComponent";

export default {
  metaInfo: {
    title: "Risk Management Programs",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS can support implementation of all facets of RMP requirements."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph,
    TabsComponent
  },
  data: () => {
    return {
      tabs: [
        {
          name: "PHA",
          content: `<div class="reading-box element-bottomshadow"><h2 data-fontsize="18" data-lineheight="27px" class="fusion-responsive-typography-calculated" style="--fontSize:18; line-height: 1.5; --minFontSize:18;">Process Hazards Analysis (PHA)</h2><div class="reading-box-additional fusion-reading-box-additional">
<ol>
<li>Determine the complexity of the Process Hazards Analysis (PHA) and the implementation schedule.</li>
<li>Define the analysis tools that are acceptable for the complexity of the process.</li>
<li>Minimally, the PHA should cover:
<ul>
<li>Process Hazards</li>
<li>Previous Incidents</li>
<li>Engineering &amp; Administrative Controls</li>
<li>Consequences of Failure of Engineering &amp; Administrative Controls</li>
<li>Facility Siting Issues</li>
<li>Human Factors.</li>
</ul>
</li>
<li>Outline team requirements.</li>
<li>Report findings, recommendations, implementation, and documentation.</li>
<li>Update/reevaluate process with PHA team every 5 years.</li>
<li>Be sure to maintain all associated PHA documentation for process life.</li>
</ol>
<p>SMS Will:</p>
<ul>
<li>Perform preliminary facility and process assessment.</li>
<li>Assist customer in prioritization of evaluation for hazardous processes.</li>
<li>Serve as PHA team leader to implement hazards analysis techniques for identification of hazards consistent with process complexity.</li>
<li>Recommend in-process energies and material response testing.</li>
<li>Document the PHA findings/recommendations.</li>
<li>Assist in establishing a system to handle the tracking of recommendations.</li>
<li>Document the corrective action resulting from the PHA findings/recommendations.</li>
<li>Participate with PHA team to periodically update/reevaluate process.</li>
<li>Assist in the establishment of a PHA documentation access and control system.</li>
</ul>
<p>Client Will:</p>
<ul>
<li>Set priorities for process evaluations.</li>
<li>Be responsible for the actual implementation of PHA findings/recommendations.</li>
<li>Be responsible for maintaining associated PHA information for the life of the process.</li>
</ul>
</div><div class="fusion-clearfix"></div></div>`,
          html: true
        },
        {
          name: "PSI",
          content: `<div><h2 data-fontsize="18" data-lineheight="27px" class="fusion-responsive-typography-calculated" style="--fontSize:18; line-height: 1.5; --minFontSize:18;">Process Safety Information (PSI)</h2><div class="reading-box-additional fusion-reading-box-additional">
<ol>
<li>Provide hazardous chemicals information (i.e., SDS information):
<ul>
<li>Toxicity</li>
<li>Thermal and chemical</li>
<li>Exposure limits</li>
<li>Reactivity and stability data</li>
<li>Incompatible materials</li>
<li>Physical data</li>
<li>Corrosivity data.</li>
</ul>
</li>
<li>Provide process technology information:
<ul>
<li>Process flow diagram</li>
<li>Applicable process chemistry</li>
<li>Maximum intended inventory</li>
<li>Safe operating parameters (e.g., temperature, pressure, flow, etc.)</li>
<li>Incompatible materials</li>
<li>Evaluation of consequences of deviations.</li>
</ul>
</li>
<li>Provide process equipment information:
<ul>
<li>Materials of construction</li>
<li>P&amp;IDs</li>
<li>Relief system design &amp; design basis</li>
<li>Ventilation system design</li>
<li>Design codes and standards employed</li>
<li>Material &amp; energy balances (processes built after May 26, 1992)</li>
<li>Safety systems (e.g., interlocks, etc.)</li>
<li>Documentation of equipment compliance with acceptable engineering practices</li>
<li>Documentation for unused equipment maintenance, testing, operation, etc.</li>
</ul>
</li>
</ol>
<p>SMS Will:</p>
<ul>
<li>Assist in development and compilation of highly hazardous materials information.</li>
<li>Conduct explosives characterization testing.</li>
<li>Review/evaluate process technology and equipment information for completeness as applicable to process hazards analysis.</li>
<li>Provide follow-on consulting services for changed or modified processes/equipment.</li>
<li>Work with existing databases to assist client in maintaining process safety information.</li>
<li>Review, organize, and publish process safety and SDS information.</li>
<li>Establish policies and procedures for the storage and retrieval of all applicable information.</li>
</ul>
<p>Client Will:</p>
<ul>
<li>Be responsible for maintenance of hazardous materials information, process technology information, or process equipment information.</li>
<li>Perform hazardous chemical testing – SMS can subcontract for this effort if necessary (e.g. toxicity, corrosivity, reactivity, sensitivity, etc.).</li>
<li>Write SDS for customers.</li>
<li>Commit to handle any material that could be called hazardous waste.</li>
</ul>
</div><div class="fusion-clearfix"></div></div>`,
          html: true
        },
        {
          name: "Training",
          content: `<div><h2 data-fontsize="18" data-lineheight="27px" class="fusion-responsive-typography-calculated" style="--fontSize:18; line-height: 1.5; --minFontSize:18;">Training</h2><div class="reading-box-additional fusion-reading-box-additional">
<ol>
<li>Initial training:
<ul>
<li>Process overview</li>
<li>Procedures</li>
<li>PHA issues</li>
<li>Safety and health hazards</li>
<li>Emergency shutdown</li>
<li>Applicable safe work practices.</li>
</ul>
</li>
<li>Refresher training:
<ul>
<li>Minimum every 3 years</li>
<li>Frequency to be determined with employees.</li>
</ul>
</li>
<li>Training documentation:
<ul>
<li>Employee identity</li>
<li>Date of training</li>
<li>Test employee understanding.</li>
</ul>
</li>
</ol>
<p>SMS Will:</p>
<ul>
<li>Assist with the development and evaluation of the training system including: Curriculum, Employee Training Matrix, Refresher Training, and Documentation.</li>
<li>Assist in the development of an employee training database system for maintaining employee training documentation (e.g., curriculum, test results).</li>
<li>Assist in training related to the Process Hazards Analysis for initial and refresher training.</li>
</ul>
<p>Client Will:</p>
<ul>
<li>Implement the training system.</li>
<li>Maintain employee training documentation.</li>
</ul>
</div><div class="fusion-clearfix"></div></div>`,
          html: true
        },
        {
          name: "Mechanical Integrity",
          content: `<div><h2 data-fontsize="18" data-lineheight="27px" class="fusion-responsive-typography-calculated" style="--fontSize:18; line-height: 1.5; --minFontSize:18;">Mechanical Integrity</h2><div class="reading-box-additional fusion-reading-box-additional">
<ol>
<li>Defines applicable equipment types.</li>
<li>Development of procedures for maintaining equipment integrity.</li>
<li>Maintenance personnel training.</li>
<li>Inspection and testing of equipment:
<ul>
<li>Process equipment</li>
<li>Inspection and testing procedures</li>
<li>Inspection and testing frequency</li>
<li>Documentation of inspections and tests</li>
</ul>
</li>
<li>Equipment deficiencies correction.</li>
<li>Quality assurance:
<ul>
<li>Verification of suitable fabrication</li>
<li>Inspection of equipment installation</li>
<li>Appropriate maintenance materials, spare parts, and equipment</li>
</ul>
</li>
</ol>
<p>SMS Will:</p>
<ul>
<li>Assist the PSM team in the preparation/review of existing maintenance procedures and training programs for process maintenance.</li>
<li>Assist in developing a mechanical integrity employee training program and training maintenance personnel regarding potential hazards associated with process maintenance.</li>
<li>Review and consult on the existing inspection and testing of process equipment.</li>
<li>Assist in the development and review of inspection and testing procedures, plans, methods, and frequency.</li>
<li>Assist in the development of a system to document inspection and test data and equipment deficiencies and corrective action.</li>
<li>Consult on adequacy of QA Program.</li>
</ul>
<p>Client Will:</p>
<ul>
<li>Be responsible for updating or maintaining procedures, training records, reporting of deficiency findings, or QA program.</li>
<li>Be responsible for equipment selection, installation, or actual maintenance of process.</li>
</ul>
</div><div class="fusion-clearfix"></div></div>`,
          html: true
        },
        {
          name: "PSSR",
          content: `<div><h2 data-fontsize="18" data-lineheight="27px" class="fusion-responsive-typography-calculated" style="--fontSize:18; line-height: 1.5; --minFontSize:18;">Pre-Startup Safety Review (PSSR)</h2><div class="reading-box-additional fusion-reading-box-additional">
<ol>
<li>Required for:
<ul>
<li>New facilities</li>
<li>Modified facilities</li>
</ul>
</li>
<li>Prestartup safety review items:
<ul>
<li>Construction/equipment complies with design specifications</li>
<li>Safety, operations, maintenance, and emergency procedures completed</li>
<li>PHA is complete and recommendations have been addressed and findings documented</li>
</ul>
</li>
</ol>
<p>SMS Will:</p>
<ul>
<li>Assist in establishing a formalized procedure for conducting pre-startup safety reviews.</li>
<li>Provide documentation to the team on pre-startup PHA issues and resolutions.</li>
</ul>
<p>Client Will:</p>
<ul>
<li>Conduct pre-startup safety reviews</li>
<li>Maintain documentation related to the reviews</li>
</ul>
</div><div class="fusion-clearfix"></div></div>`,
          html: true
        },
        {
          name: "Management Of Change",
          content: `<div><h2 data-fontsize="18" data-lineheight="27px" class="fusion-responsive-typography-calculated" style="--fontSize:18; line-height: 1.5; --minFontSize:18;">Management of Change</h2><div class="reading-box-additional fusion-reading-box-additional">
<ol>
<li>Documented procedure items:
<ul>
<li>Technical basis for change</li>
<li>Safety and health impacts</li>
<li>Operating procedure modifications</li>
<li>Time period for change</li>
<li>Authorization requirements for change</li>
</ul>
</li>
<li>Employee notification and training.</li>
<li>Updating process safety information.</li>
<li>Updating all affected procedures.</li>
</ol>
<p>SMS Will:</p>
<ul>
<li>Work with client’s existing systems to develop an MOC system which meets PSM requirements.</li>
<li>Assist the PSM team in the development/review of MOC procedures affecting covered processes.</li>
<li>Evaluate any potential process changes and review potential impacts to employee safety and health..</li>
<li>Recommend and review updates to process and maintenance procedures and training requirements.</li>
<li>Assist by drafting MOC procedures.</li>
<li>Provide on-going consulting services for hazards analysis for proposed changes.</li>
</ul>
<p>Client Will:</p>
<ul>
<li>Be responsible for the actual writing, approval, and maintenance of management of change procedures, documentation and training records.</li>
</ul>
</div><div class="fusion-clearfix"></div></div>`,
          html: true
        },
        {
          name: "Compliance Audits",
          content: `<div><h2 data-fontsize="18" data-lineheight="27px" class="fusion-responsive-typography-calculated" style="--fontSize:18; line-height: 1.5; --minFontSize:18;">Compliance Audits</h2><div class="reading-box-additional fusion-reading-box-additional">
<ol>
<li>Verification of compliance with 29 CFR 1910.119.</li>
<li>Performed every 3 years.</li>
<li>Compliance audit team defined.</li>
<li>Documentation of audit report findings.</li>
<li>Corrective action documentation for audit findings.</li>
<li>Retention of two current compliance audit reports.</li>
</ol>
<p>SMS Will:</p>
<ul>
<li>Participate with and/or lead the compliance audit team.</li>
<li>Utilize SMS-developed forms for conducting audit.</li>
<li>Assist in updating policies and procedures for 29 CFR 1910.119 compliance.</li>
<li>Issue an audit report.</li>
</ul>
<p>Client Will:</p>
<ul>
<li>Request compliance audits.</li>
<li>Maintain documentation related to the audits.</li>
<li>Certify 29 CFR 1910.119 compliance by following recommendations in the audit report.</li>
</ul>
</div><div class="fusion-clearfix"></div></div>`,
          html: true
        },
        {
          name: "Incident Investigation",
          content: `<div><h2 data-fontsize="18" data-lineheight="27px" class="fusion-responsive-typography-calculated" style="--fontSize:18; line-height: 1.5; --minFontSize:18;">Incident Investigation</h2><div class="reading-box-additional fusion-reading-box-additional">
<ol>
<li>Defines when incident investigation is required.</li>
<li>Initiation of investigation to occur ASAP but within 48 hours.</li>
<li>Investigation team definition.</li>
<li>Investigation report items defined:
<ul>
<li>Incident date</li>
<li>Investigation start date</li>
<li>Description of incident</li>
<li>Factors contributing to incident</li>
<li>Resulting recommendations</li>
</ul>
</li>
<li>Investigation recommendations/findings resolution and documentation.</li>
<li>Affected personnel provided access to incident report.</li>
</ol>
<p>SMS Will:</p>
<ul>
<li>Assist in development of the incident investigation procedure and documentation system.</li>
<li>Participate with the incident investigation team.</li>
<li>Perform special studies and testing to assist in incident investigations on an as needed basis.</li>
<li>Provide alternative methodologies for incident investigation.</li>
<li>Develop approach of analysis of trend incidents, including near misses.</li>
<li>Establish reporting requirements and processes for corrective action.</li>
</ul>
<p>Client Will:</p>
<ul>
<li>Initiate an incident investigation.</li>
<li>Maintain documentation associated with an incident investigation.</li>
<li>Select the incident investigation procedure/approach.</li>
</ul>
</div><div class="fusion-clearfix"></div></div>`,
          html: true
        },
        {
          name: "Emergency Planning",
          content: `<div><h2 data-fontsize="18" data-lineheight="27px" class="fusion-responsive-typography-calculated" style="--fontSize:18; line-height: 1.5; --minFontSize:18;">Emergency Planning and Reponse</h2><div class="reading-box-additional fusion-reading-box-additional">
<ol>
<li>Establishment of Emergency Action Plan:
<ul>
<li>In accordance with 29 CFR 1910.38(a)</li>
<li>Procedure for handling small releases</li>
<li>Compliance with 29 CFR 1910.120 (Hazardous Waste)</li>
</ul>
</li>
</ol>
<p>SMS Will:</p>
<ul>
<li>Provide consulting and assistance in developing an Emergency Action Plan consistent with applicable PSM regulations.</li>
<li>Assist in identifying and evaluating risks requiring emergency planning and response.</li>
<li>Provide subcontractor services for training employees and subcontractors in specific 29 CFR 1910 regulations.</li>
<li>Be available to provide consultation in an emergency situation.</li>
<li>Establish administrative procedures for emergency planning and response.</li>
<li>Provide ongoing services to periodically review plan for updates/revisions.</li>
</ul>
<p>Client Will:</p>
<ul>
<li>Provide personnel for hands-on participation in an emergency response.</li>
<li>Be responsible for or provide Emergency Action Plan documentation or implementation.</li>
<li>Be responsible for consulting and assistance to comply with requirements other than OSHA 1910.119. (EPA, Coast Guard, etc.)</li>
</ul>
</div><div class="fusion-clearfix"></div></div>`,
          html: true
        }
      ]
    };
  }
};
</script>
